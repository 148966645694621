::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 1px;
    background-color: #f5f5f5 !important;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: #f5f5f5 !important;
}

::-webkit-scrollbar-thumb {
    border-radius: 2px;
    -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3);
    background-color: #888 !important;
}

body {
    margin: 0;
}

.react-resizable-handle::after {
    border-right: 2px solid #fff !important;
    border-bottom: 2px solid #fff !important;
}

:root {
    --job-config-color: #99c7ff;
    --job-color: #daa2ff;
    --delivery-color: #8af59e;
    --alert-notification-color: #80f3ff;
    --job-error-color: #ff7486;
    --run-summary-color: #fc9170;
    --job-duplicate-color: #ffed66;
    --post-processing-color: #ffe100;
}

.job-config-search-card {
    border-left: 3px solid var(--job-config-color);
}

.job-search-card {
    border-left: 3px solid var(--job-color);
}
.delivery-search-card {
    border-left: 3px solid var(--delivery-color);
}
.filtered-duplicate-search-card {
    border-left: 3px solid var(--job-duplicate-color);
}
.job-error-search-card {
    border-left: 3px solid var(--job-error-color);
}
.system-error-search-card {
    border-left: 3px solid var(--run-summary-color);
}
.alert-notification-search-card {
    border-left: 3px solid var(--alert-notification-color);
}
.post-processing-search-card {
    border-left: 3px solid var(--post-processing-color);
}
